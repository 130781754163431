<template>
    <section class="rrc-pb rrc-pt">
        <div class="container">

            <div class="row d-flex align-items-center">
                <div class="col-md-4 mb-4">
                    <img class="img-fluid rrc-image-corners" src="images/recycle-sign-1x1.png" alt="RRC Cyprus">
                </div>

                <div class="offset-md-1 col-md-7 px-4">
                    <div v-html="$t('company-profile.content')"></div>
                </div>
            </div>

            <div class="row rrc-pb rrc-pt">
                <div class="col-md-4 mb-3">
                    <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/entrance-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Cyprus entrance">
                </div>

                <div class="col-md-4 mb-3">
                    <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/machinery-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Cyprus machinery">
                </div>

                <div class="col-md-4">
                    <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/separation-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Material separation process">
                </div>
            </div>

        </div>
    </section>

</template>

<script setup>

</script>
