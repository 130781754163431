<template>
    <section class="rrc-pb rrc-pt">



        <section>
            <div class="container">
                <div class="row">

                    <!-- News 3 -->
                    <div @click="redirectTo('robotic-system')" class="col-md-6 col-lg-4 px-4 mb-5">
                        <div class="mb-2">
                            <img class="img-fluid" v-lazy="{ src: 'images/robotic1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Cyprus Robotic System">
                        </div>
                        <h3 class="mb-0 rrc-homepage-news-title">{{ $t('latest-news.news3-title') }}</h3><br>
                        <p class="mb-0 small rrc-homepage-news-date">{{ $t('latest-news.news3-date') }}</p>
                        <p>{{ $t('latest-news.news3-content') }}</p>
                    </div>

                    <!-- News 2 -->
                    <div @click="redirectTo('tree-planting')" class="col-md-6 col-lg-4 px-4 mb-5">
                        <div class="mb-2">
                            <img class="img-fluid" v-lazy="{ src: 'images/tree-planting1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="tree planting event">
                        </div>
                        <h3 class="mb-0 rrc-homepage-news-title">{{ $t('latest-news.news2-title') }}</h3><br>
                        <p class="mb-0 small rrc-homepage-news-date">{{ $t('latest-news.news2-date') }}</p>
                        <p>{{ $t('latest-news.news2-content') }}</p>
                    </div>

                    <!-- News 1 -->
                    <div  @click="redirectTo('official-opening')" class="col-md-6 col-lg-4 px-4">
                        <div class="mb-2">
                            <img class="img-fluid" v-lazy="{ src: 'images/opening1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Official Opening">
                        </div>
                        <h3 class="mb-0 rrc-homepage-news-title">{{ $t('latest-news.news1-title') }}</h3><br>
                        <p class="mb-0 small rrc-homepage-news-date">{{ $t('latest-news.news1-date') }}</p>
                        <p>{{ $t('latest-news.news1-content') }}</p>
                    </div>

                </div>
            </div><!-- end of container -->
        </section>



    </section>
</template>

<script setup>
import router from "@/router";

const redirectTo = (route) => {
    router.push({name: route});
}
</script>