<template>
    <section class="rrc-pb rrc-pt">


        <!-- SERVICE 1 -->
        <section>
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="offset-md-2 col-md-8 px-4">
                        <h2 class="">{{ $t('services.service1') }}</h2>
                        <div v-html="$t('services.service1content')"></div>
                    </div>
                </div>

                <div class="row rrc-pb rrc-pt">
                    <div class="offset-md-2 col-md-4 mb-3">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/entrance-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Cyprus entrance">
                    </div>
                    <div class="col-md-4 mb-3">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/entrance2-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Cyprus entrance">
                    </div>
                </div>
            </div><!-- end of container -->
        </section>



        <!-- SERVICE 2 -->
        <section class="rrc-pt mb-5" style="background-color: rgb(249, 249, 249);">
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="offset-md-2 col-md-8 px-4">
                        <h2 class="">{{ $t('services.service2') }}</h2>
                        <div v-html="$t('services.service2content')"></div>
                    </div>
                </div>

                <div class="row rrc-pb rrc-pt">
                    <div class="col-md-4 mb-3">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/machinery-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Cyprus machinery">
                    </div>
                    <div class="col-md-4 mb-3">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/separation-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Material separation process">
                    </div>
                    <div class="col-md-4 mb-3">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/separation1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Material separation process">
                    </div>
                    <div class="col-md-4 mb-3">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/separation2-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Material separation process">
                    </div>
                    <div class="col-md-4 mb-3">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/separation3-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Material separation process">
                    </div>
                    <div class="col-md-4">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/separation4-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Material separation process">
                    </div>
                </div>
            </div><!-- end of container -->
        </section>



        <!-- SERVICE 3 -->
        <section>
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="offset-md-2 col-md-8 px-4">
                        <h2 class="">{{ $t('services.service3') }}</h2>
                        <div v-html="$t('services.service3content')"></div>
                    </div>
                </div>

                <div class="row rrc-pt">
                    <div class="offset-md-2 col-md-4 mb-4 text-center">
                        <img class="img-fluid rrc-image-corners pb-2" v-lazy="{ src: 'images/final-disposal2-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="General grounding material">
                        <span>{{ $t('services.service3image2') }}</span>
                    </div>
                    <div class="col-md-4 mb-4 text-center">
                        <img class="img-fluid rrc-image-corners pb-2" v-lazy="{ src: 'images/final-disposal3-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Sand for pipeline embankments">
                        <span>{{ $t('services.service3image3') }}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="offset-md-2 col-md-4 mb-4 text-center">
                        <img class="img-fluid rrc-image-corners pb-2" v-lazy="{ src: 'images/final-disposal4-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Material for soil enrichment/soil cover">
                        <span>{{ $t('services.service3image4') }}</span>
                    </div>

                    <div class="col-md-4 text-center">
                        <img class="img-fluid rrc-image-corners pb-2" v-lazy="{ src: 'images/final-disposal5-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Plant soil">
                        <span>{{ $t('services.service3image5') }}</span>
                    </div>
                </div>

            </div><!-- end of container -->
        </section>


    </section>
</template>

<script setup>

</script>
