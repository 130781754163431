<template>
    <div class="container">
        <nav class="navbar navbar-expand-lg rrc-nav-bar">

            <router-link class="navbar-brand" :to="{name: 'home'}">
                <div class="d-flex align-items-center">
                    <img src="images/logo-rrccyprus.png" alt="Logo" class="me-3 rrc-logo">
                </div>
            </router-link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i class="bi bi-list"></i>
            </button>

            <div class="collapse navbar-collapse" :class="!visible ? 'collapse' : ''" id="navbarNavDropdown">
                <ul class="navbar-nav me-auto">
                    <li @click="visible=!visible" class="nav-item">
                        <router-link class="nav-link" :to="{name: 'home'}">{{ $t('header.nav.home') }}</router-link>
                    </li>

                    <li @click="visible=!visible" class="nav-item">
                        <router-link class="nav-link" :to="{name: 'company-profile'}">{{ $t('header.nav.company_profile') }}</router-link>
                    </li>

                    <li @click="visible=!visible" class="nav-item">
                        <router-link class="nav-link" :to="{name: 'services'}">{{ $t('header.nav.services') }}</router-link>
                    </li>

                    <li @click="visible=!visible" class="nav-item">
                        <router-link class="nav-link" :to="{name: 'latest-news'}">{{ $t('header.nav.news') }}</router-link>
                    </li>

                    <li @click="visible=!visible" class="nav-item">
                        <router-link class="nav-link" :to="{name: 'contact'}">{{ $t('header.nav.contact') }}</router-link>
                    </li>

                    <li @click="[visible=!visible, changeActiveLanguage()]" class="nav-item pe-0">
                        <span class="nav-link cspd-languages-link"><i class="bi bi-globe"></i> GR/EN</span>
                    </li>
                </ul>

                <span class="py-2 px-3 rrc-tel"><i class="bi bi-headset"></i> <i class="bi bi-plus"></i>357 97603266</span>
            </div>

        </nav>
    </div>
</template>

<script setup>
import i18n from "@/i18n";
import { ref } from 'vue'

const visible = ref();

const changeActiveLanguage = () => {
    let lang = 'gr';
    if (i18n.global.locale.value === 'gr') {
        lang = 'en';
    }
    i18n.global.locale.value = lang;
};
</script>