import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@/assets/scss/app.scss"
import i18n from './i18n'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(VueViewer)
app.use(VueLazyLoad)
app.mount('#app')
