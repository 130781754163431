<template>
    <section class="rrc-pb rrc-pt">
        <div class="container">

            <div class="row d-flex align-items-center">
                <div class="col-md-6 mb-4">
                    <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/robotic1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="robotic system for sorting construction waste">
                </div>

                <div class="col-md-6">
                    <div v-html="$t('robotic-system.content')"></div>
                </div>
            </div>

        </div>
    </section>
</template>

<script setup>

</script>
