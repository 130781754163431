<template>
    <section class="rrc-pb rrc-pt" style="background-color: #f9f9f9">
        <div class="container">

            <div class="row">
                <div class="col-md-12 text-center">
                    <h2 class="mb-5">{{ $t('home.news.title') }}</h2>
                </div>
            </div><!-- end of row -->

            <div class="row mb-4">
                <div class="col-md-6 col-lg-4">
                    <div class="mb-3">
                        <img class="img-fluid" v-lazy="{ src: 'images/robotic1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }">
                    </div>
                    <h3 class="mb-0 rrc-homepage-news-title">{{ $t('home.news.news3-title') }}</h3><br>
                    <p class="mb-0 small rrc-homepage-news-date">{{ $t('home.news.news3-date') }}</p>
                    <p>{{ $t('home.news.news3-content') }}</p>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="mb-3">
                        <img class="img-fluid" v-lazy="{ src: 'images/tree-planting1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }">
                    </div>
                    <h3 class="mb-0 rrc-homepage-news-title">{{ $t('home.news.news1-title') }}</h3><br>
                    <p class="mb-0 small rrc-homepage-news-date">{{ $t('home.news.news1-date') }}</p>
                    <p>{{ $t('home.news.news1-content') }}</p>
                </div>

                <div class="col-md-6 col-lg-4">
                    <div class="mb-3">
                        <img class="img-fluid" v-lazy="{ src: 'images/opening1-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }">
                    </div>
                    <h3 class="mb-0 rrc-homepage-news-title">{{ $t('home.news.news2-title') }}</h3><br>
                    <p class="mb-0 small rrc-homepage-news-date">{{ $t('home.news.news2-date') }}</p>
                    <p>{{ $t('home.news.news2-content') }}</p>
                </div>
            </div><!-- end of row -->

            <div class="row">
                <div class="col-12 text-center">
                    <button @click="redirectToNews" class="btn btn-primary rrc-button px-5" type="button">{{ $t('home.news.button') }}</button>
                </div>
            </div><!-- end of row -->

        </div><!-- end of container -->
    </section>

</template>

<script setup>
import router from "@/router";

const redirectToNews = () => {
    router.push({name: 'latest-news'});
}
</script>
