<template>
    <footer class="rrc-footer py-4">
        <div class="container">

            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                    <div class="mb-3">
                        <img src="images/logo-rrccyprus-grey.png" alt="IAPD" width="200" class="img-fluid">
                    </div>
                    <p class="small text-muted">{{$t('footer.content')}}</p>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-3 ps-md-5">
                    <ul class="p-0">
                        <li><router-link :to="{name: 'home'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link1')}}</router-link></li>
                        <li><router-link :to="{name: 'company-profile'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link2')}}</router-link></li>
                        <li><router-link :to="{name: 'services'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link3')}}</router-link></li>
                        <li><router-link :to="{name: 'latest-news'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link4')}}</router-link></li>
                        <li><router-link :to="{name: 'contact'}"><i class="bi bi-arrow-right"></i> {{$t('footer.links.link5')}}</router-link></li>
                    </ul>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-5 ms-auto">
                    <div class="d-flex align-items-center mb-2">
                        <span class="rrc-footer-label text-center me-2"><i class="bi bi-geo-alt"></i></span> <span>{{$t('footer.address')}}</span>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <span class="rrc-footer-label text-center me-2"><i class="bi bi-headset"></i></span> <span>+357 25 358 835</span>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <span class="rrc-footer-label text-center me-2"><i class="bi bi-at"></i></span> <span>administration@rrccyprus.com</span>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <span class="rrc-footer-label text-center me-2"><i class="bi bi-printer"></i></span> <span>+357 25 343 705</span>
                    </div>

                    <div class="float-end">
                        <a href="https://www.facebook.com/people/Resource-Recovery-Cyprus-RRC/100087709272562/" target="_blank">
                            <i class="bi bi-facebook" style="font-size: 1.7rem; color: #b3b3b3;"></i>
                        </a>
                    </div>
                </div>

            </div><!-- end of row -->
            
        </div>
    </footer>
</template>

<script setup>

</script>