export default {
  "header": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus Society of<br>Paediatric Dentistry"])},
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
      "company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY PROFILE"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICES"])},
      "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LATEST NEWS"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])}
    }
  },
  "home": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to RRC"])},
      "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On our website you can find useful information about the disposal and processing of mixed waste."])}
    },
    "steps": {
      "step1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed Waste"])},
      "step1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon receipt, the cargo is weighed, visually inspected by specialized personnel and a file is created with all the details of each receipt for inspection purposes."])},
      "step2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
      "step2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depending on their type, the materials follow the necessary procedures and are stored in piles per category of recyclable material."])},
      "step3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposal"])},
      "step3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the work in our recycling unit, materials and products of excellent quality ready for immediate disposal and promotion, end up in the market."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])}
    },
    "news": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our News"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Our Latest News"])},
      "news1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree Planting"])},
      "news1-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June, 2020"])},
      "news1-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two tree plantings have already taken place in the area of the unit. 1300 trees have been planted and the company’s goal is to plant a total of 20 thousand trees, for the complete restoration of the former Fasoulas garbage dump!"])},
      "news2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official opening of RRC"])},
      "news2-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November, 2019"])},
      "news2-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official opening of the unit took place on November 29, 2019. The inauguration was performed by the Honourable Minister of Agriculture and Rural Development, Mr. Costas Kadis, who, among other things, characterized the unit as a model and called on other Municipalities and entrepreneurs to follow this example."])},
      "news3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A model robotic system"])},
      "news3-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A model robotic system for sorting construction waste using cutting-edge technologies was presented today at the Frederick University School of Engineering Laboratories."])},
      "news3-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February, 2023"])}
    }
  },
  "company-profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Profile"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Recycling and environmental protection has been and remains a vision and a primary goal for us.</p><p>RRC (RESOURCE RECOVERY CYPRUS) operates as a waste treatment and disposal unit resulting mainly from excavations, constructions and demolition.</p><p>It is no coincidence that what has been invested in this effort, in this project, has made the unit an exemplary model.</p><p>It is no coincidence that the Minister of Agriculture, Rural Development and Environment, Mr. Costas Kadis, described RRC as: \"Exemplary Unit!\" during the official inauguration ceremony.</p><p>Based on the production process, the solid waste to be processed is transported to the site of the unit in Fasoula, only by licensed collection and transport carriers.</p><p>For RRC a stable policy, is the proper functioning of the unit based on the regulations of the Republic of Cyprus as well as the European regulations.</p><p>The most specialized machines have been introduced in this unit covering an area of thirteen thousand square meters.</p>"])}
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "service1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waste collection"])},
    "service1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Upon receipt, the cargo is weighed, visually inspected by specialized personnel and a file is created with all the details of each receipt for inspection purposes.</p><p>At the facilities there is a pioneering separation/sorting unit with the signature of KIVERCO, which with many years of experience specializes in the solid waste industry.</p><p>In addition to the automated separation/sorting system, there is a cabin, where manual sorting is done under excellent working conditions by highly trained personnel.</p>"])},
    "service1image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waste collection"])},
    "service2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material separation process"])},
    "service2content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The purpose of the partition is to separate the materials in the specially designed slots in order to be then transferred to the next stage of processing.<p><p>Depending on their type, the materials follow the necessary procedures (breakage, sieving, shredding) and are stored in piles per category of recyclable material (general earth embankment material, sand for pipeline embankments, topsoil, soil cover and soil enrichment).</p><p>Through a special process and a special machine, branches as well as other material collected from pruning and cutting, produce soil and fertilizer.</p>"])},
    "service2image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material separation process"])},
    "service3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final disposal in the market"])},
    "service3content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>From the work in our recycling unit, materials and products of excellent quality ready for immediate disposal and promotion, end up in the market.</p>"])},
    "service3image2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General grounding material"])},
    "service3image3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sand for pipeline embankments"])},
    "service3image4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material for soil enrichment/soil cover"])},
    "service3image5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plant soil"])}
  },
  "latest-news": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest news"])},
    "news1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official opening of RRC"])},
    "news1-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official opening of the unit took place on November 29, 2019. The inauguration was performed by the Honourable Minister of Agriculture and Rural Development, Mr. Costas Kadis, who, among other things, characterized the unit as a model and called on other Municipalities and entrepreneurs to follow this example."])},
    "news1-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November, 2019"])},
    "news2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree Planting"])},
    "news2-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two tree plantings have already taken place in the area of the unit. 1300 trees have been planted and the company’s goal is to plant a total of 20 thousand trees, for the complete restoration of the former Fasoulas garbage dump! "])},
    "news2-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June, 2020"])},
    "news3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A model robotic system"])},
    "news3-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A model robotic system for sorting construction waste using cutting-edge technologies was presented today at the Frederick University School of Engineering Laboratories."])},
    "news3-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February, 2023"])}
  },
  "tree-planting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree Planting"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Two tree plantings have already taken place in the area of the unit. 1300 trees have been planted and the company’s goal is to plant a total of 20 thousand trees, for the complete restoration of the former Fasoulas garbage dump! The effort will be expanded in collaboration with the districts, Municipalities of Limassol and other organized groups.</p><p>In addition, a carob forest will be created in the area on the initiative of the Community of Fasoula and the University of Cyprus.<p><p>The Director of RRC, Mr. Savvinos Lofitis, has announced the construction of a children’s playground, an environmental park for school visits and much more.</p>"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June, 2020"])}
  },
  "official-opening": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official opening of RRC"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The official opening of the unit took place on November 29, 2019.</p><p>The inauguration was performed by the Honourable Minister of Agriculture and Rural Development, Mr. Costas Kadis, who, among other things, characterized the unit as a model and called on other Municipalities and entrepreneurs to follow this example.</p><p>The Mayor of Limassol, Mr. Nikos Nikolaidis and the CEO of RRC, Mr. Savvinos Lofitis welcomed the attendees and gave them a guided tour of the premises.</p><p>The Mayor of Limassol underlined, among other things, that there was once a garbage dump in this area. Today, he added, garbage has come into recycling and is no longer a threat to the environment.</p><p>Mr. Savvinos Lofitis emphasized that his company’s sole guide had and has the protection of the environment, through European standards and thanked the authorities of the Municipality and the Government for the cooperation.</p>"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November, 2019"])}
  },
  "robotic-system": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A model robotic system"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>A model robotic system for sorting construction waste using cutting-edge technologies was presented today at the Frederick University School of Engineering Laboratories. The presentation was made in the presence of the Minister of Agriculture, Rural Development and Environment, Kostas Kadis, the President of the Frederick University Council, Ms. Natasa Friderikou and members of the DEFEAT research project, coordinated by the Frederick Research Center, in the framework of which the robotic system was developed.</p><p>The robotic system, which is owned by our company, at this stage serves to separate construction waste in order to be used for the creation of an innovative, refractory and thermally insulating building material for building facades.</p><p>Congratulations to all those who contributed to the development of the robotic system and the implementation of the DEFEAT project and for their presentations today to the project coordinator, Dr. Dimitris Nikolaidis, Associate Professor at the Department of Civil Engineering, Dr. Pavlos Mavrommatidis, Lecturer in the Department of Electrical, Computer and Information Engineering at Frederick University and Researcher at FRC, Dr. Dimitris Demetriou, Researcher at the University of Cyprus and Mr. Mr. Dimitrios Dimitrios Dimitrios, PhD, University of Cyprus, and Mr. Alexis Onoufriou, Director of FRC, who referred to the research conducted by the research organization, oriented towards addressing the modern challenges of our world.</p>"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February, 2023"])}
  },
  "footer": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRC (RESOURCE RECOVERY CYPRUS) operates as a waste treatment and disposal unit resulting mainly from excavations, constructions and demolition."])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["83 Myrtou, 4551, Mesa Geitonia, Limassol Cyprus"])},
    "links": {
      "link1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "link2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Profile"])},
      "link3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "link4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
      "link5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "link6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Policy"])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["83 Myrtou, 4551, Mesa Geitonia, Limassol, Cyprus"])},
    "mailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 Zinas Kanther, 3035 Limassol, Cyprus"])}
  }
}