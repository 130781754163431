<template>
    <section class="rrc-pb rrc-pt">

        <section class="rrc-pb">
            <div class="container">
                <div class="row d-flex align-items-center">

                    <div class="col-md-6 mb-4">
                        <img class="img-fluid rrc-image-corners" v-lazy="{ src: 'images/entrance-3x2.jpg', loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="RRC Cyprus entrance">
                    </div>

                    <div class="col-md-6 px-4">
                        <div class="d-flex align-items-center mb-3">
                            <span class="rrc-contact-label text-center me-2"><i class="bi bi-geo-alt"></i></span> <span>{{$t('contact.address')}}</span>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <span class="rrc-contact-label text-center me-2"><i class="bi bi-envelope"></i></span> <span>{{$t('contact.mailing')}}</span>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <span class="rrc-contact-label text-center me-2"><i class="bi bi-headset"></i></span> <span>+357 97603266</span>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <span class="rrc-contact-label text-center me-2"><i class="bi bi-at"></i></span> <span>administration@rrccyprus.com</span>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <span class="rrc-contact-label text-center me-2"><i class="bi bi-printer"></i></span> <span>+357 25 343 705</span>
                        </div>
                    </div>

                </div>
            </div>
        </section>


        <section class="rrc-pt rrc-pb mb-5" style="background-color: rgb(249, 249, 249);">
            <div class="container">
                <div class="row d-flex align-items-center">
                    <div class="col-md-12 mb-4">
                        <iframe class="rrc-contact-map" loading="lazy" src="https://maps.google.com/maps?q=%20RRC%20-%20Resource%20Recovery%20Cyprus%20&amp;t=m&amp;z=13&amp;output=embed&amp;iwloc=near" title=" RRC - Resource Recovery Cyprus " aria-label=" RRC - Resource Recovery Cyprus "></iframe>
                    </div>
                </div>
            </div>
        </section>
    </section>

</template>

<script setup>

</script>
