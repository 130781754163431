<template>
    <section class="rrc-pb rrc-pt rrc-mb" style="background-color: #f9f9f9">

        <div class="container">
            <div class="row" v-viewer="viewerOptions">
                <div v-for="src in images" :key="src" class="col-md-3 mb-3">
                    <img class="img-fluid rrc-image-corners" v-lazy="{ src: src, loading: 'images/loading-3x2.jpg', error: 'images/error-3x2.jpg' }" alt="Fasoula Tree Planting">
                </div>
            </div>
        </div>
    </section>

</template>

<script setup>
import {ref} from "vue";

const viewerOptions = ref({
    "inline": false,
    "button": false,
    "navbar": false,
    "title": false,
    "toolbar": {
        zoomIn: 0,
        zoomOut: 0,
        oneToOne: 0,
        reset: 0,
        prev: {
            show: 4,
            size: 'large',
        },
        play: 0,
        next: {
            show: 4,
            size: 'large',
        },
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
    },
    "tooltip": false,
    "movable": false,
    "zoomable": false,
    "rotatable": false,
    "scalable": false,
    "transition": true,
    "fullscreen": false,
    "keyboard": false,
})

const images = ref([
    "images/tree-planting2-3x2.jpg",
    "images/tree-planting3-3x2.jpg",
    "images/tree-planting4-3x2.jpg",
    "images/tree-planting5-3x2.jpg",
    "images/tree-planting6-3x2.jpg",
]);
</script>
