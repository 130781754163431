<template>
    <section class="rrc-homepage-steps-container rrc-mb">
        <div class="container">


            <div class="row d-flex justify-content-center">

                <div class="col-md-4 col-lg-3 text-center mb-5">
                    <div class="rrc-box p-3">
                        <div class="mb-3">
                            <img width="96" class="img-fluid" src="images/bin.png" alt="">
                        </div>
                        <h3>{{ $t('home.steps.step1-title') }}</h3>
                        <p>{{ $t('home.steps.step1-description') }}</p>
                    </div>
                </div>

                <div class="col-md-4 col-lg-3 text-center mb-5">
                    <div class="rrc-box p-3">
                        <div class="mb-3">
                            <img width="96" class="img-fluid" src="images/recycle.png" alt="">
                        </div>
                        <h3>{{ $t('home.steps.step2-title') }}</h3>
                        <p>{{ $t('home.steps.step2-description') }}</p>
                    </div>
                </div>

                <div class="col-md-4 col-lg-3 text-center mb-5">
                    <div class="rrc-box p-3">
                        <div class="mb-3">
                            <img width="96" class="img-fluid" src="images/lorry.png" alt="">
                        </div>
                        <h3>{{ $t('home.steps.step3-title') }}</h3>
                        <p>{{ $t('home.steps.step3-description') }}</p>
                    </div>
                </div>
            </div><!-- end of row -->

            <div class="row">
                <div class="col-12 text-center">
                    <button @click="redirectToServices" class="btn btn-primary rrc-button px-5" type="button">{{ $t('home.steps.button') }}</button>
                </div>
            </div><!-- end of row -->

        </div><!-- end of container -->
    </section>
</template>

<script setup>
import router from "@/router";

const redirectToServices = () => {
    router.push({name: 'services'});
}
</script>