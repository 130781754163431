import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CompanyProfileView from '../views/CompanyProfileView.vue'
import ServicesView from '../views/ServicesView.vue'
import LatestNewsView from '../views/LatestNewsView.vue'
import TreePlantingView from '../views/TreePlantingView'
import OfficialOpeningView from '../views/OfficialOpeningView'
import RoboticSystemView from '../views/RoboticSystemView'
import ContactView from '../views/ContactView'
import i18n from "@/i18n";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            titleGR: 'Αρχική',
            titleEN: 'Home',
        },
    },
    {
        path: '/company-profile',
        name: 'company-profile',
        component: CompanyProfileView,
        meta: {
            titleGR: 'Εταιρικό Προφίλ',
            titleEN: 'Company Profile',
        },
    },
    {
        path: '/services',
        name: 'services',
        component: ServicesView,
        meta: {
            titleGR: 'Υπηρεσίες',
            titleEN: 'Services',
        },
    },
    {
        path: '/latest-news',
        name: 'latest-news',
        component: LatestNewsView,
        meta: {
            titleGR: 'Τελευταία Νέα',
            titleEN: 'Latest News',
        },
    },
    {
        path: '/tree-planting',
        name: 'tree-planting',
        component: TreePlantingView,
        meta: {
            titleGR: 'Τελευταία Νέα',
            titleEN: 'Tree Planting',
        },
    },
    {
        path: '/official-rrc-opening',
        name: 'official-opening',
        component: OfficialOpeningView,
        meta: {
            titleGR: 'Επίσημα εγκαίνια της RRC',
            titleEN: 'Official opening of RRC',
        },
    },
    {
        path: '/model-robotic-system',
        name: 'robotic-system',
        component: RoboticSystemView,
        meta: {
            titleGR: 'Ένα πρότυπο ρομποτικό σύστημα διαλογής οικοδομικών αποβλήτων',
            titleEN: 'A model robotic system for sorting construction waste',
        },
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView,
        meta: {
            titleGR: 'Επικοινωνία',
            titleEN: 'Contact',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: {
            titleGR: '404',
            titleEN: '404',
        },
        component: () => import('../components/PageNotFound.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    routes
})

router.beforeEach((to, from, next) => {
    let metaTitle = `${to.meta.titleEN} | Resource Recovery Cyprus`;
    if (i18n.global.locale.value === 'gr') {
        metaTitle = `${to.meta.titleGR} | Μονάδα Επεξεργασίας / Ανακύκλωσης Α.Ε.Κ.Κ `;
    }
    document.title = metaTitle;
    next()
})

export default router
